import React from 'react';

import Link from '../../components/Link';
import Button from '../../components/Button';
import Card from '../../components/Card';
import {GroupIcon} from '../../components/svg';
import {useAnalytics} from '../../hooks';

const PracticeCard = ({loading = true, coaches = []}) => {
  const {tracker} = useAnalytics();

  if (loading) return <Placeholder />;

  return (
    <Card>
      <span className="text-lg font-semibold mb-1">Practice interviews</span>
      <span className="block text-gray-500 leading-snug max-w-xl">
        Practice makes perfect. Get the feedback you need with peer mock interviews
        and expert coaching sessions.
      </span>
      {coaches.map((coach) => (
        <Link
          href={`/coach/${coach.slug}?src=dashboard`}
          onClick={() => tracker.dashboardClickCoaching({coach: coach.slug})}
          key={coach.slug}
        >
          <div className="flex justify-between items-center mt-1 -mx-2 p-2 rounded-lg transition ease-in-out duration-150 hover:bg-gray-50">
            <div className="flex">
              <img className="flex-shrink-0 h-16 w-16 object-cover rounded-md mr-4" alt={coach.Name} src={coach.Photo[0].url} />
              <div className="flex flex-col justify-center mr-5">
                <span className="block text-xs font-semibold text-gray-400 uppercase">Recommended</span>
                <span className="block text-base md:text-lg font-medium leading-tight">{`Book a coaching session with ${coach.Name}`}</span>
                <span className="block text-gray-500 leading-none">{coach.Title}</span>
              </div>
            </div>
            <Button outline className="w-30 text-sm px-4 flex-shrink-0 hidden lg:block">Book Now</Button>
          </div>
        </Link>
      ))}
      <Link
        href="/practice?src=dashboard"
        onClick={() => tracker.dashboardClickDuet()}
      >
        <div className="flex justify-between items-center mt-1 -mx-2 p-2 rounded-lg transition ease-in-out duration-150 hover:bg-gray-50">
          <div className="flex">
            <div className="flex flex-shrink-0 justify-center items-center h-16 w-16 rounded-md mr-4 bg-blue-200">
              <GroupIcon className="h-9 w-9" stroke="#308AA2" />
            </div>
            <div className="flex flex-col justify-center mr-5">
              <span className="block text-base md:text-lg font-medium leading-tight">
                Peer mock interviews
              </span>
              <span className="block text-gray-500 leading-tight">
                Practice with peers every day on our live mock interview platform.
              </span>
            </div>
          </div>
          <Button outline className="w-30 text-sm px-4 flex-shrink-0 hidden lg:block">Join Now</Button>
        </div>
      </Link>
      <div className="flex justify-center pt-5">
        <Link
          href="/coaching?src=dashboard"
          onClick={() => tracker.dashboardClickMoreCoaching()}
          className="text-indigo text-sm font-medium hover:text-indigo-900 focus:text-indigo-900"
        >
          See more coaches
        </Link>
      </div>
    </Card>
  );
};

const Placeholder = () => (
  <Card className="animate-pulse">
    <div className="space-y-2 mb-4 ">
      <div className="h-6 bg-gray-100 rounded w-1/4" />
      <div className="h-6 bg-gray-100 rounded w-4/5" />
    </div>
    <div className="flex mb-4">
      <div className="flex-shrink-0 h-16 w-16 rounded-md bg-gray-100 mr-5" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-6 bg-gray-100 rounded w-4/5" />
        <div className="h-4 bg-gray-100 rounded w-1/5" />
      </div>
    </div>
    <div className="flex mb-4">
      <div className="flex-shrink-0 h-16 w-16 rounded-md bg-gray-100 mr-5" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-6 bg-gray-100 rounded w-4/5" />
        <div className="h-4 bg-gray-100 rounded w-1/5" />
      </div>
    </div>
    <div className="flex">
      <div className="flex-shrink-0 h-16 w-16 rounded-md bg-gray-100 mr-5" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-6 bg-gray-100 rounded w-2/5" />
        <div className="h-4 bg-gray-100 rounded w-2/5" />
      </div>
    </div>
  </Card>
);

export default PracticeCard;
